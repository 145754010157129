import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { matchPath, useNavigate } from "react-router-dom";
import { GlobalStyle as BaseGlobalStyle, Theme, themeOptions as baseThemeOptions } from '@commonsku/styles';
import { isAuthorized, isShopOpen, getPopups, getFullCart, getOrderById, getGalleryOrderByParent } from '../selectors';

import { createCloseCart, createLoadShop, fetchProductItems } from '../actions/shop';

import Overlay from '../components/helpers/Overlay';
import ErrorBoundary from '../components/ErrorBoundary';
import PopupsOverlay from '../components/Overlay';
import Home from '../components/shop/Home';
import ShopGallery from '../components/shop/ShopGallery';
import Cart from '../components/shop/Cart';
import Checkout from '../components/shop/Checkout';
import Receipt from '../components/shop/Receipt';

import createPopup from '../popup-factory';
import { WebsocketContextProvider } from '../context/websocket-context';
import ShopChat from '../components/chat/ShopChat';
import { getNotificationUserList } from '../helpers/chat';
import ScrollToTop from '../components/ScrollToTop';
import { window } from '../global';
import { getWithExpiry } from '../local-storage-util';
import { SKUFontDefinitionStyles } from '../components/helpers/createGlobalStyle';
import { hexToHSL } from '../utils';
import { hideShopIntro, isShopPreviewing } from '../components/shop/helpers';
import { getPublicViewTemplateDropdown } from '../selectors/dropdowns';
import { PublicViewTemplate } from '../models/PublicViewTemplate';
import { setFilter } from '../redux/gallery';
import CartProvider from '../context/CartProvider';
import PublicViewEntityProvider from '../context/PublicViewEntityProvider';
import PublicViewProvider from '../context/PublicViewProvider';
import { parseOrderToPublicViewEntity } from '../hooks/usePublicViewEntity';
import { getDefaultPublicViewTemplateTheme } from '../components/shop/Storefront/helpers';
import PublicViewTemplateDataProvider from '../context/PublicViewTemplateDataProvider';
import { useIdentity } from '../hooks';

const DEFAULT_COLOR = '#5ca3b6';

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
.resku .gallery-root {
  ${p.galleryRootBg ? `background-color: ${p.galleryRootBg};` : ''}
  ${p.isCartOpen ? 'overflow: hidden !important;' : ''}
}
.select-contacts-popup-content .select-existing, .select-address-popup-content .select-existing {
  overflow-y: hidden !important;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control {
  border: none;
  border-color: unset;
  box-shadow: none;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control .commonsku-styles-select__value-container {
  padding-right: 0;
}

.shop .mobile-sidebar.slide-from-left {
  height: calc(100vh - 78px - env(safe-area-inset-bottom)) !important;
}

div.popup.intro-popup > div.intro-popup-content.popup-content {
  height: 100%;
}

#feat-prod.item-preview h4 {
  color: #404f59 !important;
}

.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__menu
  .commonsku-styles-select__option.commonsku-styles-select__option--is-selected {
  background: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90) .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90) .commonsku-styles-select__menu {
  border: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90)
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator,
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90)
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator > svg {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90 .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: var(--color-neutrals-90);
}
.shop .extra-categories-options .extra-categories-select.active .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90 .commonsku-styles-select__menu {
  border: 1px solid var(--color-neutrals-90);
  border-top: 1px solid var(--color-neutrals-90);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 1px 1px 0px var(--color-neutrals-90),
    -1px -1px 0px var(--color-neutrals-90),
    1px -1px 0px var(--color-neutrals-90),
    -1px 1px 0px var(--color-neutrals-90)};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator,
.shop .extra-categories-options .extra-categories-select.neutrals-90
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator > svg {
    color: var(--color-neutrals-90);
}

.resku #hero-banner .row .product-column.column:last-child:not(:first-child),
.resku #hero-banner .row .product-column.columns:last-child:not(:first-child) {
  float: left;
}

.popup.shop-item-popup .popup-content.shop-item-popup-content {
  height: 100%;
}

.shop .title-bar #shop-title h1.new-shop-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /*
  &:hover {
    white-space: normal;
    overflow: unset;
    text-overflow: unset;
  }
  */
}
`;

function getThemeOptions(template_color = DEFAULT_COLOR) {
  const hslColor = hexToHSL(template_color);
  const selectColors = baseThemeOptions.colors.select;
  const inputColors = baseThemeOptions.colors.input;
  return {
    ...baseThemeOptions,
    colors: {
      ...baseThemeOptions.colors,
      primary25: `hsl(${hslColor[0]}, 100%, 25%)`,
      primary75: `hsl(${hslColor[0]}, 100%, 25%)`,
      primary50: `hsl(${hslColor[0]}, 100%, 50%)`,
      primary: template_color,
      select: {
        ...selectColors,
        border: template_color,
        active: {
          ...selectColors.active,
          border: template_color,
        },
        hover: {
          ...selectColors.hover,
          border: template_color,
        },
        dropdownIcon: {
          ...selectColors.dropdownIcon,
          color: template_color,
        },
      },
      input: {
        ...inputColors,
        border: template_color,
        hover: {
          ...inputColors.hover,
          border: template_color,
        },
        active: {
          ...inputColors.active,
          border: template_color,
        },
        iconWrapper: {
          ...inputColors.iconWrapper,
          hover: {
            ...inputColors.iconWrapper.hover,
            background: `hsl(${hslColor[0]}, 100%, 25%)`,
          },
        },
        icon: {
          ...inputColors.icon,
          active: {
            ...inputColors.icon.active,
            fill: template_color,
          },
          hover: {
            ...inputColors.icon.hover,
            fill: template_color,
          },
        },
      },
    },
  };
}

const PublicOrderShopApp = ({
  order,
  panel,
  params,
  popups,
  authorized,
  public_view_templates_list,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const selected = params.item_id;
  const userIdentity = useIdentity();
  const filter = useSelector(s => s.gallery.filter);
  const tenantOptions = useSelector(s => {
    const o = getOrderById(s, params);
    let result = s.entities.options;
    if (result) {
      result.avatar = o?.company_data?.avatar ?? '/images/favicons/favicon-96x96.png';
    }
    return result;
  });
  const editingCartItemId = useSelector(s => s.temp.editingCartItemId);
  const gallery = useSelector(s => getGalleryOrderByParent(s, { parent_type: 'ORDER', parent_id: params.order_id }));
  const cart = useSelector(s => getFullCart(s, { buy_inventory: order.buy_inventory ?? 0 }));
  const notifyOptions = useSelector(s => s.entities.options ? s.entities.options.chat_notification_user : false);

  const entityValue = useMemo(
    () => parseOrderToPublicViewEntity(gallery),
    [gallery]
  );

  const notifyUser = useMemo(
    () => getNotificationUserList(notifyOptions, order.sales_rep_id, order.client_rep_id),
    [notifyOptions, order.sales_rep_id, order.client_rep_id]
  );

  const public_view_template_name = useMemo(
    () => (
      public_view_templates_list
        .find(st => st.public_view_template_id === entityValue.entityTemplateId)?.public_view_template_name || ''
    ), [entityValue.entityTemplateId, public_view_templates_list]
  );

  const [state, setState] = useState({
    selected: params.item_id,
    contact: null,
    showCart: false,
    cartItemDetailsId: null,
    hideIntro: false,
    isPreviewing: false,
  });

  const onCloseCart = () => dispatch(createCloseCart(params.order_id));

  useEffect(() => {
    const localStorageContact = getWithExpiry('client_contact');
    if (localStorageContact) {
        setState(s => ({
          ...s,
          contact: localStorageContact
        }));
    }

    window.addEventListener('message', handleMessageReceived);
    return () => {
      window.removeEventListener('message', handleMessageReceived);
    };
  }, []);

  useEffect(() => {
    const cartMatch = matchPath({ path: '/order/:order_id/cart' }, window.location.pathname);
    if (panel === 'cart' || cartMatch) {
      Promise.resolve(onCloseCart()).then(() => {
        navigate(
          `/order/${order.order_id}/shop${+order.buy_inventory === 1 ? '?buy_inventory=true' : ''}`
        );
      });
    }
  }, [panel, order.order_id, order.buy_inventory]);

  useEffect(() => {
    setState(s => ({
      ...s, selected: params?.item_id || null,
    }));
  }, [params, panel]);

  function onFilterChange(filter) {
    let destination, parsedFilter;
    switch (filter) {
      case 'ALL_PRODUCTS':
        destination = 'shop';
        parsedFilter = 'shop';
        break;
      case 'HOME':
        destination = '';
        parsedFilter = '';
        break;
      default:
        destination = 'shop';
        parsedFilter = filter;
        break;
    }
    navigate(`/order/${params.order_id}/${destination}`);
    dispatch(setFilter(parsedFilter));
  }

  function handleMessageReceived(event) {
    var origin = event.origin || event.originalEvent.origin;
    if (origin !== window.location.origin) {
      return;
    }

    if (typeof event.data === 'object' && event.data.call === 'sendShopValue') {
      setState(s => ({
        ...s,
        hideIntro: event.data.value.hideIntro,
        isPreviewing: event.data.value.isPreviewing,
      }));
    } else if (typeof  event.data === 'object' && event.data.call === 'sendShopFilterChange') {
      const { filter } = event.data.value; // HOME, ALL_PRODUCTS, category_id
      onFilterChange(filter);
    }
  }

  function onClickCart(value = null, cartItemDetailsId) {
    setState(s => ({ ...s, showCart: value === null ? !state.showCart : value, cartItemDetailsId }));
  }

  const chatChannel = window.location.href.split('/').slice(0, 5).join('/');
  const productPageStyle = useMemo(() => {
    const template_data = order.template_data[
      entityValue.entityTemplateId
    ] || {};
    return (template_data.product_page_style || {value: 'CAROUSEL'}).value;
  }, [order.template_data, entityValue.entityTemplateId]);

  const productInPopup = productPageStyle === 'POPUP';
  const productInDropdown = productPageStyle === 'DROPDOWN';
  const isPosterTheme = public_view_template_name === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
  const publicViewTemplate = getDefaultPublicViewTemplateTheme(
    gallery, public_view_templates_list
  );
  const themeOptions = getThemeOptions(publicViewTemplate.templateData.template_color);

  return (
    <Theme theme={themeOptions}>
      <BaseGlobalStyle
        theme={themeOptions}
        galleryRootBg={panel === 'checkout' || isPosterTheme ? '#fff' : ''}
        isCartOpen={state.showCart}
        additionalStyles={additionalGlobalStyle}
      />
      <ErrorBoundary>
        {!productInPopup && !productInDropdown && <ScrollToTop />}
        <WebsocketContextProvider
          identity={userIdentity}
          notifyUser={notifyUser}
          canVoiceCall={false}
          channel={chatChannel}
          tenantOptions={tenantOptions}
          tenantId={order.tenant_id}
          defaultColor={publicViewTemplate.templateData.template_color || DEFAULT_COLOR}
          getBots={false}
        >
          <CartProvider entityId={entityValue.entityId} isEntityFree={entityValue.entityIsFree}>
            <PublicViewTemplateDataProvider initialValue={publicViewTemplate}>
              <PublicViewEntityProvider initialValue={entityValue}>
                <PublicViewProvider
                  cart={cart}
                  cartItemDetailsId={state.cartItemDetailsId}
                  contact={state.contact}
                  editingCartItemId={editingCartItemId}
                  filter={filter}
                  fullHeight={['checkout', 'receipt', 'cart'].includes(panel)}
                  onClickCart={onClickCart}
                  selectedItemId={params.item_id}
                  showCart={state.showCart}
                >
                  <div style={{ boxSizing: 'border-box' }}>
                    <Cart
                      cart={cart}
                      params={state.params}
                      contact={state.contact}
                      showCart={state.showCart}
                      onClose={() => onClickCart(false)}
                      onClickCart={onClickCart}
                      fullHeight={['checkout', 'receipt', 'cart'].includes(panel)}
                      showDetailsItemId={state.cartItemDetailsId}
                      is_shop_free={order.is_shop_free || order.is_free}
                      buy_inventory={order.buy_inventory ?? 0}
                    />
                    {state.showCart && <Overlay />}
                    {authorized ?
                      <Panel
                        panel={panel}
                        state={state}
                        order={order}
                        params={params}
                        productPageStyle={productPageStyle}
                        onClickCart={onClickCart}
                      />
                      : null}
                    {popups.map((p, idx) => createPopup(p, idx))}
                    <PopupsOverlay popups={popups} />
                    <ShopChat />
                  </div>
                </PublicViewProvider>
              </PublicViewEntityProvider>
            </PublicViewTemplateDataProvider>
          </CartProvider>
        </WebsocketContextProvider>
      </ErrorBoundary>
    </Theme>
  );
};

const mapStateToProps = (state, ownProps) => {
  const order = getOrderById(state, ownProps.params);
  const public_view_templates_list = getPublicViewTemplateDropdown(state);
  const publicViewTemplate = getDefaultPublicViewTemplateTheme(
    order, public_view_templates_list
  );
  return {
    order,
    popups: getPopups(state),
    open: isShopOpen(state),
    authorized: isAuthorized(state),
    publicViewTemplate,
    public_view_templates_list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPassword: (order_id, password) => {
    return dispatch(createLoadShop(order_id, password)).then((result) => {
      return !!result;
    });
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...ownProps,
  ...propsFromState,
  popups: !propsFromState.open ? [{
    component: 'ClosedShopPopup',
  }].concat(propsFromState.popups) : propsFromState.authorized ? propsFromState.popups : [
    {
      component: 'PasswordPopup',
      title: `${propsFromState.order.order_type} - ${propsFromState.order.form_number}`,
      placeholder: 'Enter shop password',
      submitColor: propsFromState.publicViewTemplate.templateData.template_color || DEFAULT_COLOR,
      onSubmit: password => propsFromDispatch.onSubmitPassword(ownProps.params.order_id, password),
    }
  ].concat(propsFromState.popups)
});

function Panel({
  state,
  panel,
  order,
  params,
  onClickCart,
  productPageStyle,
}) {
  const { selected, contact, hideIntro, isPreviewing } = state;
  const productInPopup = productPageStyle === 'POPUP';
  const productInDropdown = productPageStyle === 'DROPDOWN';
  const productInCarousel = productPageStyle === 'CAROUSEL';

  const commonProps = {
    order: order,
    params: params,
    contact: contact,
    onClickCart: onClickCart,
    showCart: state.showCart,
    isCartOpen: state.showCart,
    productInPopup: productInPopup,
    productInDropdown: productInDropdown,
    productInCarousel: productInCarousel,
    hideIntro: hideIntro || hideShopIntro() || params.hideHelp || params.hideIntro,
    isPreviewing: isPreviewing || isShopPreviewing() || params.previewShop,
    is_shop_free: order.is_free || 0,
    buy_inventory: order.buy_inventory ?? 0,
  };

  switch (panel) {
    case 'home':
      return <Home {...commonProps} />;
    case 'shop':
      return <ShopGallery selected={selected} {...commonProps} />;
    case 'cart':
      return <ShopGallery selected={selected} {...commonProps} />;
    case 'checkout':
      return <Checkout
        {...commonProps}
        // needed for (constructor of) checkout component
        entityId={order.order_id}
        entityType={'ORDER'}
        clientId={order.client_id || ''}
        aggregate={order.is_aggregate || 0}
        billingAddressType={order.billing_address_type || ''}
        shippingAddressType={order.shipping_address_type || ''}
        billingContactType={order.billing_contact_type || ''}
        shippingContactType={order.shipping_contact_type || ''}
      />;
    case 'receipt':
      return <Receipt {...commonProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PublicOrderShopApp);
